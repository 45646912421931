<template>
	<div class="container">
		<Header></Header>
		<div class="rank-banner-wrap">
			<div class="rank-banner wrap">
				<div class="rank-banner-left fl">
					<div class="rank-banner-left-title">
						<h2>{{head.mainTitle}}</h2>
					</div>
					<div class="rank-banner-left-info">
						<div class="rank-banner-left-infoItem">
							<img src="../../assets/images/rank-banner-icon1.png" alt="" class="rank-banner-left-icon" />
							<div class="rank-banner-left-infoTxt">
								<h4>{{head.totalVisits}}</h4>
								<p>关注人数</p>
							</div>
						</div>
						<div class="rank-banner-left-infoItem">
							<img src="../../assets/images/rank-banner-icon2.png" alt="" class="rank-banner-left-icon"/>
							<div class="rank-banner-left-infoTxt">
								<h4>{{head.brandNumber}}</h4>
								<p>参与品牌</p>
							</div>
						</div>
					</div>
					<div class="rank-banner-left-step">
						<div class="rank-banner-left-stepItem">
							<h4>初选公布</h4>
							<p><img src="../../assets/images/icon-arrow-yuan-yellow.png" alt="" /></p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4><img src="../../assets/images/rank-banner-link.png" alt="" class="rank-banner-left-stepItem-line"/></h4>
							<p>&nbsp;</p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4>公示期</h4>
							<p>国民监督</p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4><img src="../../assets/images/rank-banner-link.png" alt="" class="rank-banner-left-stepItem-line"/></h4>
							<p>&nbsp;</p>
						</div>
						<div class="rank-banner-left-stepItem">
							<h4>正式发布</h4>
							<p>{{head.updateTime}}</p>
						</div>
					</div>
				</div>
				<div class="rank-banner-right fr">
					<img src="../../assets/images/rank-banner-right.png" alt="" />
				</div>
			</div><!-- rank-banner end -->
		
		</div>
		<!-- banner-wrap end -->
		<!-- <div class="breadcrumbs wrap">
			<img src="../../assets/images/icon-home.png" alt=""  class="breadcrumbs-icon"/>
			当前位置: 品牌排行榜 > {{head.mainTitle}}
		</div> -->
		
		<div class="i-main wrap mt-30">
			<div class="rank-m1 border-10 rankMore-m1">
				<!-- <h3>卫生间门十大品牌排行榜</h3> -->
				<P>{{head.pageDesc}}</P>
				<div class="rank-m1-img">
					<img :src="imgUrl+head.industryTrans" alt=""/>
				</div>
			</div><!-- rank-m1 end -->
			<div class="rank-con clearfix mt-20">
				<div class="rm-list clearfix">
					<ul>
						<li v-for="(item,index) in leftList" :key="index">
							<a :href="`/brand/detail?brandId=${ Number(item.brandId)+9999}`" style="text-decoration: none;">
								<div class="rm-list-img">
									<img :src="imgUrl+item.brandLogo" alt="" />
								</div>
								<div class="rm-list-info">
									<h3>{{item.brandName}}</h3>
									<p>{{item.enterName}}</p>
									<div class="rm-list-num"><span>访问量: <i v-if="item.totalVisits>0">{{item.totalVisits}}</i><i v-else>0</i></span></div>
								</div>
							</a>
						</li>
					</ul>
				</div>
				<div class="page-box">
					<span class="page-span" @click="firstPage()">首页</span>
					<el-pagination
					background
					:current-page.sync="currentPage"
					 @current-change="handleCurrentChange"
					 :page-size="pageSize"
					  layout="prev, pager, next"
					  :total="total">
					</el-pagination>
					<span class="page-span" @click="lastPage()">末页</span>
				</div>
			</div><!-- rank-con end -->
		</div><!-- i-main end  -->
		<div class="i-m9-wrap mt-40 border-top-1">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
		<Footer></Footer>
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
	</div>
</template>

<script>
	import {getHead,getList} from "../../api/ranking.js";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "rankingMore",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				imgUrl:'http://zgall.com/prod-api',
				industryId:"",//接收id
				leftList:[],
				total:20,
				head:{},//头部	
				pageNum:1,
				pageSize:20,
				currentPage: 1,
			};
		},
	
		created() {
			const industryId = this.$route.query.industryId; // 获取参数值
			console.log('接收industryId',industryId); // 输出参数值id
			this.industryId = industryId
			this.initPage();
		},

		computed: {},
		mounted() {},
		methods: {
			initPage(){
				this.getBanner()
				this.getLeftList()
			},


			//首页头部
			getBanner(){
				getHead(this.industryId).then(res=>{
					// console.log(res.data.data,'头部');
					this.head = res.data.data
				})
			},
			
			//品牌列表
			getLeftList(){
				getList({
					industryId:this.industryId,
					pageSize:this.pageSize,
					pageNum:this.pageNum
				}).then(res=>{
					console.log(res.data,'品牌列表');
					var dataCon = res.data
					this.leftList = dataCon.rows
					this.total = dataCon.total
				})
			},

			handleCurrentChange(val) {
				 this.currentPage = val; 
				 this.pageNum = val;
				 console.log(`当前第 ${val} 条`);
				 this.getLeftList()
			},
			  
			firstPage(){
				this.pageNum = 1
				this.currentPage = 1
				this.getLeftList()
			},
			
			lastPage(){
				const total = this.total
				const pageSize = this.pageSize
				this.pageNum = Math.ceil(total / pageSize);
				this.currentPage = Math.ceil(total / pageSize);
				this.getLeftList()
			},
			
			//跳转链接
			clickLink(item) {
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query:{brandId: id}
				});
				
			},
			
		},
	}
</script>

<style scoped>
	@import "../../assets/css/ranking.css";
</style>